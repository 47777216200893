<template>
  <div class="yapan">
    <div class="list">
      <div class="list-item">
        <div class="list-item-label">
          主队分析
        </div>
        <div class="list-item-content">
          {{info.status_z}}
        </div>
      </div>

      <div class="list-item">
        <div class="list-item-label">
          客队分析
        </div>
        <div class="list-item-content">
          {{info.status_k}}
        </div>
      </div>

      <div class="list-item">
        <div class="list-item-label">
          星辰阁评论
        </div>
        <div class="list-item-content">
          {{info.star_all}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getShowAllDataById } from "@/api/fenxi.js";
export default {
  data() {
    return {
      id: 0,
      info: {
        zteam: "",
        kteam: ""
      }
    };
  },
  mounted() {
    this.id = this.$route.query.id;
    this.getShowAllDataById();
  },
  methods: {
    getShowAllDataById() {
      getShowAllDataById(this.id).then(res => {
        this.$set(this, "info", res.data);
        this.info.zteam_logo_adress = this.info.zteam_logo_adress.replace(
          /small/,
          "big"
        );
        this.info.kteam_logo_adress = this.info.kteam_logo_adress.replace(
          /small/,
          "big"
        );
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.yapan {
  font-size: 16px;
  .list {
    .list-item {
      border: 1px solid;
       + .list-item {
      border-top: none;
      }
      display: flex;
      height: 100px;
      .list-item-label {
        background: #16A085;
        color: #fff;
        width: 100px;
        text-align: center;
        border-right: 1px solid #000;
        display: flex;
        justify-content: center;
        align-items: center;

      }
      .list-item-content {
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }


}
</style>
