<template>
  <div>
    <div class="title">亚盘智能查询</div>
    <el-divider></el-divider>
    <div class="search-box">
      <el-form :inline="true" label-position="right" :model="search">
        <el-form-item label="公司" label-width="40px">
          <el-select
            size="small"
            v-model="search.region"
            placeholder="选择公司"
          >
            <el-option label="区域一" value="shanghai"></el-option>
            <el-option label="区域二" value="beijing"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="初盘" label-width="70px">
          <el-select
            size="small"
            v-model="search.region"
            placeholder="选择初盘"
          >
            <el-option label="区域一" value="shanghai"></el-option>
            <el-option label="区域二" value="beijing"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="主队初水位" label-width="110px">
          <el-select
            size="small"
            v-model="search.region"
            placeholder="选择主队初水位"
          >
            <el-option label="区域一" value="shanghai"></el-option>
            <el-option label="区域二" value="beijing"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="客队初水位" label-width="110px">
          <el-select
            size="small"
            v-model="search.region"
            placeholder="选择客队初水位"
          >
            <el-option label="区域一" value="shanghai"></el-option>
            <el-option label="区域二" value="beijing"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label-width="40px">
          <div style="height:40px;width:170px"></div>
        </el-form-item>

        <el-form-item label="临盘" label-width="70px">
          <el-select
            size="small"
            v-model="search.region"
            placeholder="选择临盘"
          >
            <el-option label="区域一" value="shanghai"></el-option>
            <el-option label="区域二" value="beijing"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="主队临水位" label-width="110px">
          <el-select
            size="small"
            v-model="search.region"
            placeholder="选择主队临水位"
          >
            <el-option label="区域一" value="shanghai"></el-option>
            <el-option label="区域二" value="beijing"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="客队临水位" label-width="110px">
          <el-select
            size="small"
            v-model="search.region"
            placeholder="选择客队临水位"
          >
            <el-option label="区域一" value="shanghai"></el-option>
            <el-option label="区域二" value="beijing"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <div class="total">
      <span>主赢盘概率：11</span>
      <span>走盘概率：11</span>
      <span>容主输盘概率：11</span>
    </div>
    <el-table :data="tableData" stripe border>
      <el-table-column
        prop="saiji"
        label="联赛"
        align="center"
        header-align="center"
      />
      <el-table-column
        prop="saiji"
        label="季赛"
        align="center"
        header-align="center"
      />
      <el-table-column
        prop="saiji"
        label="日期"
        align="center"
        header-align="center"
      />
      <el-table-column
        prop="saiji"
        label="主队"
        align="center"
        header-align="center"
      />
      <el-table-column
        prop="saiji"
        label="客队"
        align="center"
        header-align="center"
      />
      <el-table-column
        prop="saiji"
        label="比分"
        align="center"
        header-align="center"
      />
      <el-table-column
        prop="saiji"
        label="结果"
        align="center"
        header-align="center"
      />
    </el-table>
  </div>
</template>

<script>
// import { asiaData } from "@/api/zhihuixing.js";
export default {
  data() {
    return {
      tableData: [],
      search: {}
    };
  },

  async mounted() {
    // let { data } = await asiaData();
    // this.tableData = data;
  }
};
</script>

<style lang="scss" scoped>
.title {
  height: 28px;
  font-size: 20px;
  font-weight: bold;
  line-height: 28px;
  color: #16a085;
  &::before {
    content: "";
    display: inline-block;
    width: 4px;
    height: 15px;
    background: #16a085;
    margin-right: 5px;
  }
}
.el-divider {
  margin-top: 5px;
  margin-bottom: 15px;
}
.search-box {
  background: #f6f7f8;
  border-radius: 6px;
  padding: 15px 20px;
  margin-bottom: 15px;
  .el-form {
    margin-bottom: -12px;
  }
  .el-form-item {
    margin-bottom: 12px;
  }
  .el-select {
    width: 130px;
  }
}
.total {
  font-size: 14px;
  text-align: right;
  margin-bottom: 20px;
  span {
    margin-left: 70px;
  }
}
</style>
